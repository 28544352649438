/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {

	function stickyFooter () {
		var windowHeight = $(window).height();
		//var bodyHeight = $('body').outerHeight();
		var bodyHeight = $('header.banner').outerHeight() + $('.menu-dashboard').outerHeight() + $('.defileParent').outerHeight() + $('#tab4').outerHeight() + $('.partners').outerHeight() + $('footer').outerHeight();
		if (windowHeight > bodyHeight) {
			var hauteurMain = windowHeight - $('footer').outerHeight() - $('.partners').outerHeight() - $('.menu-dashboard').outerHeight() - $('.defileParent').outerHeight();
			$('main').css('min-height', hauteurMain + 'px');
		}
	}
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				//JS pour  les boutons file input
				$(document).on('change', ':file', function () {
					var input = $(this),
						numFiles = input.get(0).files ? input.get(0).files.length : 1,
						label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
					input.trigger('fileselect', [numFiles, label]);
				});

				// We can watch for our custom `fileselect` event like this
				$(document).ready(function () {
					var annonce = $('.jobs-title').text();
					if(typeof(annonce) !== 'undefined' ) {
						$('.annonce').val(annonce);
					}



					$(':file').on('fileselect', function (event, numFiles, label) {

						var input = $(this).parents('.input-group').find(':text'),
							log = numFiles > 1 ? numFiles + ' files selected' : label;

						if (input.length) {
							input.val(log);
						} else {
							if (log) {
								alert(log);
							}
						}

					});
				});

				// JavaScript to be fired on all pages
				$('.match-height-row').matchHeight({byRow: true});
				$('.match-height-row-2').matchHeight({byRow: true});
				$('.match-height-row-3').matchHeight({byRow: true});


				var is_scrolled = false;
				var l = window.location;
				var url = l.protocol + "//" + l.host + "/" + l.pathname.split('/')[1];
				var base_url = l.protocol + "//" + l.host + "/";

				if (url !== base_url) {
					$('.home-logo').css('opacity', 1);
				}

				$(window).scroll(function () {
					if ($(window).scrollTop() > 0 && is_scrolled === false) {
						$('header.banner').addClass('is_scrolled');
						$('.top-line').addClass('hidden');
						$('.home-logo').css('opacity', '1');

						if ($('.logo-hp').length > 0) {
							$('.logo-hp').css('opacity', '0');
						}

						is_scrolled = true;
					}
					else if ($(window).scrollTop() === 0) {
						is_scrolled = false;
						$('header.banner').removeClass('is_scrolled');
						$('.top-line').removeClass('hidden');
						if (url === base_url) {
							$('.home-logo').css('opacity', '0');
						} else {
							$('.home-logo').css('opacity', '1');
						}
						if ($('.logo-hp').length > 0) {
							$('.logo-hp').css('opacity', '1');
						}
					}
				});

				$('.owl-partenaires').owlCarousel({
					loop: true,
					items: 12,
					dotsEach: 1,
					autoplay: 1,
					autoplayTimeout: 2500,
					smartSpeed: 1000,
					autoplayHoverPause: false,
					autoWidth: true,
					margin: 30
				});

				$('.owl-realisations').owlCarousel({
					loop: true,
					items: 1,
					responsive: {
						768: {
							items: 3
						},
						576: {
							items: 2
						}
					},
					margin: 30,
					autoplay: 1,
					autoplayTimeout: 2500,
					smartSpeed: 1000,
					autoplayHoverPause: false,
				});

				$(".open-side-menu, .close-side-menu").click(function () {
					$("#side-mask").toggleClass("side-mask-opened");
					$("#side-menu").toggleClass("opened");
				});

				$(".box-clic").click(function () {
					var urlPage = $(this).find("a").attr("href");
					window.location = urlPage;
				});

				$('.box-clic').on("touchstart", function (e) {
					"use strict"; //satisfy the code inspectors
					var link = $(this).find("a").attr("href");
					if ($(this).hasClass('touch-hover')) {
						return true;
					} else {
						$(this).addClass("touch-hover");
						$('.box-clic').not(this).removeClass("touch-hover");
						e.preventDefault();
						return false; //extra, and to make sure the function has consistent return points
					}
				});

				$('.liste-services > li ').hover(
					function () {
						$(this).find('.picto > img').attr('src', $(this).find('.picto > img').attr('data-hover-img'));
						$(this).find('.picto').css('background-color', $(this).find('.picto').attr('data-hover-bg'));
					},
					function () {
						$(this).find('.picto > img').prop('src', $(this).find('.picto > img').attr('data-img'));
						$(this).find('.picto').css('background-color', 'transparent');
					}
				);

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$(".chiffre-counter").appear(function () {
					var id = $(this).attr('id');
					var suffixe = $(this).attr('data-suffixe');
					var chiffre = $(this).attr('data-counter');
					var prefixe = $(this).attr('data-prefixe');
					var compteur = new CountUp(id, 0, chiffre, 0, 4, {
						separator: ' ',
						suffix: suffixe,
						prefix: prefixe
					});
					compteur.start();
				});

				var searchContainer = $('.search-container');

				stickyFooter();

				$('.searchBar').on('click', function () {
					searchContainer.toggleClass('visibility-hide');
					searchContainer.toggleClass('visibility');
				});

				$('.search-container > .close').on('click', function () {
					searchContainer.removeClass('visibility');
					searchContainer.addClass('visibility-hide');
				});

				$('.input-search-formation').click(function () {
					$(this).removeAttr('placeholder');
				});

				$('.input-search-formation').focusout(function () {
					$(this).attr('placeholder', 'RECHERCHER');
				});

				$('.title-color').each(function (index, element) {
					var heading = $(element);
					var word_array, last_word, first_part;

					word_array = heading.html().split(/\s+/);
					last_word = word_array.pop();
					first_part = word_array.join(' ');

					if (last_word !== first_part) {
						heading.html([first_part, ' <span class="last-word">', last_word, '</span>'].join(''));
					}
				});
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
				$('.services-menu .text').matchHeight({byRow: true});

				$('.owl-temoignages').owlCarousel({
					nav: false,
					dots: true,
					loop: true,
					margin: 70,
					//autoWidth:true,
					items: 1,
					autoplay: true,
					autoplayHoverPause: true,
					smartSpeed: 1000,
					autoplayTimeout: 2000,
					//navText: ['<i class=" fa fa-chevron-circle-right"></i>','<i class=" fa fa-chevron-circle-left"></i>'],
				});

			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		},
		'certifications_et_partenariats_de_telmo': {
			init: function () {
				$('.listing-partner').appear(function () {
					$('img', this).each(function (i, el) {
						setTimeout(function () {
							$(el).removeClass('invisible').addClass('animated zoomIn');
						}, 500 + (i * (Math.floor(Math.random() * Math.floor(10) * 30))));
					});
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
